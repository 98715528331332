import React, { useEffect, useState } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import InvoiceContainer from "../../../CommonComponent/InvoiceContainer/InvoiceContainer";
import classes from "../CreateClient/CreateClient.module.css";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import { InvoiceStyles4, InvoiceStyles6 } from "../../../utils/style";
import dropdownIcon from "../../../../../assets/downIcon.png";
import "react-toastify/dist/ReactToastify.css";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
import ClearIcon from "@mui/icons-material/Clear";
import { ToastifyError } from "../../../../../App";
import { IoIosAddCircle } from "react-icons/io";
import { employeeId } from "../../../../../Utils/constants";
import cancel from "../../../../../assets/cancell.png";
import calender from "../../../../../assets/calblue.png"

import {
  getCityList,
  getStateList,
  getCountryList,
  getCurrencyType,
  addViewCity,
  addeditClientDetails,
  addCurrency,
  getIdClientById,
  getClientListFilter,
  getExistsByClientId,
} from "../../../../../Utils/axios/PortalManagement";
import axios from "axios";
import BreadCrum from "../../../breadcrums/BreadCrum";
import DatePicker from "react-datepicker";
import moment from "moment";
import { CustomInputClg, CustomInputCross } from "../../../../../GenericComponent/ui/calenderStyle";
import { customStyles7 } from "../../../../atsDashboard/utils/style";
const CreateClientList = ({ setNextScreen, prevData, setPrevData }) => {
  const baseUrl = process.env.REACT_APP_PARTNER_MANAGEMENT;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [localOptions, setLocalOptions] = useState([]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [AddCity, setAddCity] = useState("");

  const [formData, setFormData] = useState({
    empId: Number(employeeId),
    clientName: "",
    legalClientName: "",
    email: "",
    pointOfContactOfClient: "",
    mobileNo: "",
    salesOwnerName: "",
    spocDesignation: "",
    gstin: "",
    sezStatus: null,
    currencyId: "",
    agreement: "",
    address1: "",
    address2: "",
    stateId: "",
    cityId: "",
    pinCode: "",
    countryId: "",
    clientDomain: "",
    linkedInUri: "",
    webLinkUri: "",
    dueDate: "",
    clientHeadOfficeLocation: "",
    spocCurrentLocation: "",
    isStateAndCityMandatory: false,
  });
  const [formCurrency, setFormCurrency] = useState({
    currencyName: "",
    currencyCode: "",
  });
  const mutation = useMutation(addeditClientDetails, {
    onSuccess: (res) => {
      setNextScreen("clientList");
    },
  });

  const editMutation = useMutation(addeditClientDetails, {
    onSuccess: (res) => {
      setNextScreen("viewClient");
      setPrevData((prevData) => ({
        ...prevData,
        page1: "clientList",
        page2: "viewClient",
        page3: null,
        page4: null,
        active: "viewClient",
      }));
    },
    onError: (error) => { },
  });

  const [validate, setValidate] = React.useState(false);
  const [validate1, setValidate1] = React.useState(false);
  const [validate2, setValidate2] = React.useState(false);

  useEffect(() => {
  }, [formData])

  const handleCreatePartner = () => {
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    const isStateCityValid = formData?.isStateAndCityMandatory ? formData?.stateId && formData?.cityId : true;

    if (formData?.mobileNo.length > 10 || formData?.mobileNo.length < 10) {
      setValidate(true)
    }
    if (
      formData?.clientName &&
      formData?.legalClientName &&
      formData?.pointOfContactOfClient?.trim() &&
      formData?.salesOwnerName?.trim() &&
      formData?.gstin &&
      formData?.spocDesignation && 
      isStateCityValid &&
      formData?.currencyId &&
      isValidEmail(formData?.email) &&
      formData?.mobileNo?.trim() &&
      formData?.mobileNo.length === 10
    ) {
      let data = {
        clientName: formData?.clientName,
        legalClientName: formData?.legalClientName,
        empId: formData?.empId,
        email: formData?.email,
        pointOfContactOfClient: formData?.pointOfContactOfClient,
        mobileNo: formData?.mobileNo,
        salesOwnerName: formData?.salesOwnerName,
        spocDesignation: formData?.spocDesignation,
        gstin: formData?.gstin,
        sezStatus: formData?.sezStatus,
        currencyId: formData?.currencyId,
        agreement: selectedFiles,
        address1: formData?.address1,
        address2: formData?.address2,
        stateId: formData?.stateId,
        cityId: formData?.cityId,
        pinCode: formData?.pinCode,
        countryId: formData?.countryId,
        clientDomain: formData?.clientDomain,
        linkedInUri: formData?.linkedInUri,
        webLinkUri: formData?.webLinkUri,
        dueDate: formData?.dueDate,
        clientHeadOfficeLocation: formData?.clientHeadOfficeLocation,
        spocCurrentLocation: formData?.spocCurrentLocation
      };
      setValidate(false);

      if (prevData?.active === "editClient") {
        data.clientId = formData?.clientId;
        editMutation.mutate(data);
        return;
      }

      mutation.mutate(data);
    } else {
      setValidate(true);
    }
  };
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };

  const handleInputClick = (e) => {
    e.target.addEventListener("mousewheel", (ev) => {
      e.target.blur();
    });
  };

  const handleKeyDown = (e) => {
    // Prevent 'e' from being entered
    if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const { data: GetCityTypeData, refetch } = useQuery(
    ["getCityList", formData.stateId],
    () => getCityList(formData.stateId),{
      enabled: !!formData.stateId 
    }
  );
  // GetCityTypeData?.sort((a, b) =>
  //   a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  // );

  const sezOption = [
    { value: true, label: " Yes" },
    { value: false, label: "No" },
  ];

  const { data: getCurrency1, refetch: refetch2 } = useQuery(
    ["getCurrencyType"],
    getCurrencyType
  );
  const GetCurrencyDataOption = getCurrency1?.map((e) => ({
    value: e?.id,
    label: `${e?.currencyName} (${e?.currencyCode})`,
  }));

  const handleFileDeletionmulti = (idx) => {
    let arraydata = [...selectedFiles];
    let docUrl = arraydata.splice(idx, 1);
    handleFileDeletionApi(docUrl);
    setSelectedFiles([...arraydata]);
  };

  async function handleFileDeletionApi(idx) {
    try {
      const response = await axios.put(baseUrl + `/delete-file-url?url=${idx}`);
      if (response.status === 200) {
        setFormData({ ...formData, agreement2: "" });
      } else {
      }
    } catch (error) { }
  }

  const checkfileTypemulti = (value) => {
    switch (value) {
      case "pdf":
        return <BsFilePdf />;
      case "docx":
        return <AiOutlineFileWord />;
    }
  };

  async function handleFileUploadmulti(file) {
    setFormData({ ...formData, agreement2: file.name });
    const formDataa = new FormData();
    formDataa.append("file", file);

    try {
      const response = await axios.post(baseUrl + "/upload-file", formDataa);
      if (response.status === 200) {
        setFormData({ ...formData, agreement2: "" });
        setSelectedFiles([...selectedFiles, response.data?.responseData]);
      } else {
        setSelectedFiles([]);
      }
    } catch (error) {
      // handle error
    }
  }
  const handleShow = (expenseId) => {
    setShow(true);
  };

  const handleShow2 = (expenseId) => {
    setShow2(true);
  };
  // const GetCityDataOption = GetCityTypeData?.map((e) => ({
  //   value: e.id,
  //   label: e.name,
  // }));
  const GetCityDataOption = Array.isArray(GetCityTypeData)
  ? GetCityTypeData.map((e) => ({
      value: e.id,
      label: e.name,
    }))
  : [];


  const { data: GetStateTypeData, refetch: refetchStateList } = useQuery(["getStateList", formData.countryId], () => getStateList(formData.countryId),
  { enabled: !!formData.countryId });
  // GetStateTypeData?.sort((a, b) =>
  //   a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  // );

  const GetStateDataOption = Array.isArray(GetStateTypeData) ? GetStateTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  })) : [];

  const { data: GetCountryTypeData } = useQuery(["getCountryList"], getCountryList);
  // GetCountryTypeData?.sort((a, b) =>
  //   a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  // );

  const GetCountryDataOption = GetCountryTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const mutationCity = useMutation(addViewCity, {
    onSuccess: (res) => {
      refetch();
    },
  });
  const handleAdd = () => {
    if (AddCity === "") {
      setValidate1(true);
    } else {
      mutationCity.mutate({ id: AddCity });
      setValidate1(false);
      setShow(false);
    }
  };

  const mutationCurrency = useMutation(addCurrency, {
    onSuccess: (res) => {
      refetch2();
    },
  });

  const handleAddCurrency = () => {
    if (formCurrency?.currencyName && formCurrency?.currencyCode) {
      setValidate2(false);
      setShow2(false);
      mutationCurrency.mutate(formCurrency);
    } else {
      setValidate2(true);
    }
  };

  const handleAddCancle = () => {
    setShow(false);
  };

  const handleAddCancle2 = () => {
    setShow2(false);
  };

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick} onKeyDown={props.onClick} tabIndex={0}>
        <label ref={ref}>{props.value || props.placeholder}</label>
        <img
          src={calender}
          style={{ position: "absolute", right: "5%", top: "13%" }}
          alt="calender"
        />
      </div>
    );
  });

  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          alt="cancel"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
          onKeyDown={() => setFormData({ ...formData, [props?.properties]: "" })}
          tabIndex={0}
        />
      </div>
    );
  });

  const { data: ClientDataById } = useQuery(
    ["getViewClient1"],
    () =>
      prevData?.active === "editClient" && getIdClientById(prevData?.clientId)
  );
  const { data: getClientData } = useQuery(
    ["getClientsForFilter"],
    getClientListFilter,
    {
      refetchOnWindowFocus: false,
    }
  );
  const clientOptions = getClientData?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
    };
  });
  useEffect(() => {
    setLocalOptions(clientOptions);
  }, [getClientData])

  const handleCreate = (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setLocalOptions([...clientOptions, newOption]);
    setFormData({
      ...formData,
      clientName: inputValue,
    });
  }
  const handleChange = async (selectedOption) => {
    if (selectedOption) {
      try {
        // Assuming selectedOption.value is the clientId
        const existsData = await getExistsByClientId(selectedOption.value);
        setDisableSubmitButton(!existsData);

        setFormData({
          ...formData,
          clientName: selectedOption.label,
        });
      } catch (error) {
        console.error('Error fetching exists data:', error);
      }
    } else {
      setFormData({
        ...formData,
        clientName: '',
      });
      setDisableSubmitButton(false);
    }
  };
  useEffect(() => {
    if (ClientDataById) {
      setSelectedFiles(ClientDataById?.agreement);
    }
  }, [ClientDataById]);

  useEffect(() => {
    if (ClientDataById) {
      setFormData({
        ...formData,
        clientId: prevData?.clientId,
        empId: Number(employeeId),
        clientName: ClientDataById?.clientName,
        legalClientName: ClientDataById?.legelClientName,
        email: ClientDataById?.email,
        pointOfContactOfClient: ClientDataById?.pointOfContactOfClient,
        mobileNo: ClientDataById?.mobileNo,
        salesOwnerName: ClientDataById?.salesOwnerName,
        spocDesignation: ClientDataById?.spocDesignation,
        gstin: ClientDataById?.gstNumber,
        sezStatus: ClientDataById?.sezStatus,
        currencyId: ClientDataById?.currencyId,
        agreement: ClientDataById?.agreement,
        address1: ClientDataById?.address,
        address2: ClientDataById?.address2,
        stateId: ClientDataById?.stateId,
        cityId: ClientDataById?.cityId,
        pinCode: ClientDataById?.pinCode,
        countryId: ClientDataById?.countryId,
        clientDomain: ClientDataById?.clientDomain,
        linkedInUri: ClientDataById?.clientLinKedInURi,
        webLinkUri: ClientDataById?.clientwebUri,
        dueDate: ClientDataById?.dueDate,
        clientHeadOfficeLocation: ClientDataById?.clientHeadOfficeLocation,
        spocCurrentLocation: ClientDataById?.spocCurrentLocation
      });
    }
  }, [ClientDataById, prevData]);
  return (
    <>
      <div style={{ marginLeft: "10px" }}>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      <Container>
        <InvoiceContainer>
          <Row>
            <Col md={4}>
              <p className={classes.label1}>
                Client Trade Name <span style={{ color: "red" }}>*</span>
              </p>
              {/* <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    clientName: e.target.value,
                  });
                }}
                value={formData?.clientName}
              /> */}
              <div>
                <CreatableSelect
                  isClearable
                  styles={customStyles7}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  onCreateOption={handleCreate}
                  onChange={handleChange}
                  value={localOptions?.find((option) => option.label === formData.clientName) || null}
                  options={localOptions}
                  formatCreateLabel={(userInput) => `+ Add "${userInput}"`}
                />
              </div>
              <p
                className={
                  validate && !formData?.clientName
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Client Trade Name
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                Client Legal Name <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    legalClientName: e.target.value,
                  });
                }}
                value={formData?.legalClientName}
              />
              <p
                className={
                  validate && !formData?.legalClientName?.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Client Legal Name
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                Point of Contact of Client{" "}
                <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    pointOfContactOfClient: e.target.value,
                  });
                }}
                value={formData?.pointOfContactOfClient}
              />
              <p
                className={
                  validate && !formData?.pointOfContactOfClient?.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Point of Contact of Client
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Email ID <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    email: e.target.value,
                  });
                }}
                value={formData?.email}
              />
              <p
                className={
                  validate &&
                    (!formData?.email || !isValidEmail(formData?.email))
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                {validate &&
                  (!formData?.email || !isValidEmail(formData?.email))
                  ? "Please Enter a Valid Email ID"
                  : "Please Enter Name"}
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Mobile Number <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="number"
                className={classes.smallInput}
                maxLength={10}
                minLength={10}
                value={formData?.mobileNo}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  if (inputVal.length > 10) {
                    inputVal = inputVal.slice(0, 10);
                  }
                  setFormData({
                    ...formData,
                    mobileNo: inputVal,
                  });
                }}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) =>
                  (e.keyCode === 38 ||
                    e.keyCode === 40 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.preventDefault()
                }
                onKeyPress={(e) =>
                  ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                    e.which > 57 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.prevent
                }
              />
              <p
                className={
                  validate && !formData?.mobileNo?.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Mobile Number
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                Sales Owner Name
                <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    salesOwnerName: e.target.value,
                  });
                }}
                value={formData?.salesOwnerName}
              />
              <p
                className={
                  validate && !formData?.salesOwnerName?.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Sales Owner Name
              </p>
            </Col>
            <Col md={4} className={classes.TestData}>
              <p className={classes.label}>
                Client Spoc Designation
                <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    spocDesignation: e.target.value,
                  });
                }}
                value={formData?.spocDesignation}
              />
              <p
                className={
                  validate && !formData?.spocDesignation?.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Client Spoc Designation
              </p>
            </Col>
            <Col md={4} className={classes.TestData}>
              <p className={classes.label1}>
                GSTIN <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    gstin: e.target.value,
                  });
                }}
                value={formData?.gstin}
                onKeyDown={handleKeyDown}
                onClick={handleInputClick}
              />
              <p
                className={
                  validate && !formData?.gstin?.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter GSTIN
              </p>
            </Col>

            <Col md={4} className={classes.TestData}>
              <p className={classes.label1}>SEZ</p>
              <Select
                className={classes.stateWidth}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                value={sezOption?.find((e) => e.value === formData?.sezStatus)}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    sezStatus: e?.value,
                  });
                }}
                options={sezOption}
              />
            </Col>

            <Col md={3} style={{ marginBottom: "18px" }}>
              <p className={classes.label1}>
                Currency <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                className={classes.stateWidth}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                options={GetCurrencyDataOption}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    currencyId: e?.value,
                  });
                }}
                value={GetCurrencyDataOption?.find(
                  (e) => e.value === formData?.currencyId
                )}
              />
              <p
                className={
                  validate && !formData?.currencyId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Select Currency
              </p>
            </Col>
            <Col md={1} style={{ marginBottom: "18px" }}>
              <div className={classes.remove_icon}>
                <IoIosAddCircle
                  size={30}
                  onClick={() => {
                    handleShow2();
                  }}
                />
              </div>
            </Col>
            <Col md={4} style={{ marginBottom: "18px" }}>
              <p className={classes.label1}>Address 1</p>
              <input
                type="text"
                value={formData?.address1}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    address1: e.target.value,
                  });
                }}
              />
            </Col>
            <Col md={4} style={{ marginBottom: "18px" }}>
              <p className={classes.label1}>Address 2</p>
              <input
                type="text"
                value={formData?.address2}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    address2: e.target.value,
                  });
                }}
              />
            </Col>
            <Col md={4} style={{ marginBottom: "18px" }}>
              <p className={classes.label1}>Country</p>
              <Select
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                menuPlacement="top"
                options={GetCountryDataOption}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    countryId: e?.value,
                    isStateAndCityMandatory: e?.label === "India",
                    stateId: null,  
                    cityId: null,    
                  });
                  refetchStateList(e?.value);
                }}
                value={GetCountryDataOption?.find(
                  (e) => e.value === formData?.countryId
                ) || null}
              />
              {/* <input
                type="text"
                className={classes.smallInput2}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    country: e.target.value,
                  });
                }}
                value={formData?.country}
              /> */}
            </Col>
            <Col md={4} style={{ marginBottom: "18px" }}>
              <p className={classes.label1}>
                State{" "}
                {formData?.isStateAndCityMandatory && <span style={{ color: "red" }}>*</span>}
              </p>
              <Select
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                menuPlacement="top"
                isDisabled={!formData.countryId}
                options={GetStateDataOption}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    stateId: e?.value,
                    cityId: null,
                  });
                }}
                value={GetStateDataOption?.find(
                  (e) => e.value === formData?.stateId
                ) || null}
              />
              <p
                className={
                  validate && formData?.isStateAndCityMandatory && !formData?.stateId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Select State
              </p>
            </Col>

            <Col md={4} style={{ marginBottom: "18px" }}>
              <p className={classes.label1}>
                City{" "}
                {formData?.isStateAndCityMandatory && <span style={{ color: "red" }}>*</span>}
              </p>
              <Select
                options={GetCityDataOption}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                menuPlacement="top"
                isDisabled={!formData.stateId}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    cityId: e?.value,
                  });
                }}
                value={GetCityDataOption?.find(
                  (e) => e.value === formData?.cityId
                ) || null}
              />
              <p
                className={
                  validate && formData?.isStateAndCityMandatory && !formData?.cityId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter City
              </p>
            </Col>
            {/* <Col md={1} style={{ marginBottom: "18px" }}>
              <IoIosAddCircle
                className={classes.remove_icon2}
                size={30}
                onClick={() => {
                  handleShow();
                }}
              />
            </Col> */}
            <Col md={4}>
              <p className={classes.label1}>Pincode</p>
              <input
                type="text"
                className={classes.smallInput2}
                value={formData?.pinCode}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    pinCode: e.target.value && /^\d{1,6}$/.test(e.target.value) ? e.target.value : "",
                  });
                }}
              />
              <p
                className={
                  validate && formData?.pinCode && !/^\d{1,6}$/.test(formData?.pinCode)
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Invalid Pincode
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Client Domain</p>
              <input
                type="text"
                className={classes.smallInput2Domain}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    clientDomain: e.target.value,
                  });
                }}
                value={formData?.clientDomain}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Client Website</p>
              <input
                type="text"
                className={classes.smallInput2Domain}
                value={formData?.webLinkUri}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    webLinkUri: e.target.value,
                  });
                }}
              />
            </Col>

            <Col md={4}>
              <p className={classes.label1}>Client LinkedIn</p>
              <input
                type="text"
                className={classes.smallInput2Domain}
                value={formData?.linkedInUri}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    linkedInUri: e.target.value,
                  });
                }}
              />
            </Col>

            <Col md={4}>
              <p className={classes.label1}>Due Date</p>
              <div className={classes.smallInput2Domain}>
                <DatePicker
                  onChange={(date) => {
                    console.log(date)
                    setFormData({
                      ...formData,
                      dueDate: date,
                    });
                  }}
                  selected={
                    formData?.dueDate
                      ? moment(formData?.dueDate).toDate()
                      : null
                  }
                  dateFormat="dd MMM yyyy"
                  customInput={
                    formData?.dueDate ? (
                      <CustomInputCross properties="dueDate" />
                    ) : (
                      <CustomInputClg />
                    )
                  }
                />
              </div>
            </Col>

            <Col md={4}>
              <p className={classes.label}>Client Head-office location</p>
              <input
                type="text"
                className={classes.smallInput2Domain}
                value={formData?.clientHeadOfficeLocation}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    clientHeadOfficeLocation: e.target.value,
                  });
                }}
              />
            </Col>

            <Col md={4}>
              <p className={classes.label}>SPOC Current Location</p>
              <input
                type="text"
                className={classes.smallInput2Domain}
                value={formData?.spocCurrentLocation}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    spocCurrentLocation: e.target.value,
                  });
                }}
              />
            </Col>



            <Col md={4} className={classes.editTextTop}>
              <p className={classes.label1}>Agreement</p>

              <div className={classes.chooseFile}>
                <div>
                  {formData?.agreement2 ? (
                    <div
                      className={classes.rmpdf}
                      style={{ paddingTop: "3px" }}
                    >
                      {
                        <>
                          {checkfileTypemulti(
                            formData?.agreement2?.split(".")[1]
                          )}
                          &nbsp;
                          <span>
                            {formData?.agreement2 &&
                              formData?.agreement2?.length > 25
                              ? `${formData?.agreement2
                                ?.split("@")[1]
                                ?.substring(0, 25)}...`
                              : formData?.agreement2}
                          </span>
                        </>
                      }
                    </div>
                  ) : (
                    <input
                      id="file-upload"
                      accept="image/*, .pdf, .doc, .docx"
                      type="file"
                      multiple
                      className={classes.upload_resume}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          const fileSize = file.size / 1024 / 1024; // File size in MB
                          if (fileSize <= 10) {
                            handleFileUploadmulti(file);
                          } else {
                            ToastifyError(
                              "File size exceeds the limit of 10MB."
                            );
                            e.target.value = null; // Reset the file input value
                            return;
                          }
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            </Col>

            <Col md={4}>
              <p className={classes.label}></p><br />
              {/* <div> */}
              {selectedFiles?.length > 0 ? (
                <>
                  {selectedFiles?.map((i, idx) => (
                    <div key={idx} >
                      <div className={classes.rmpdf}>
                        <div className={classes.smallInputPdf}>{`${i
                          ?.split("@")[1]
                          ?.substring(0, 27)}...`}</div>
                        <ClearIcon
                          onClick={() => handleFileDeletionmulti(idx)}
                          className={classes.crox}
                        />
                      </div>
                    </div>
                  ))}
                </>
              ) : null}
              {/* </div> */}
            </Col>





          </Row>
        </InvoiceContainer>
      </Container>
      <button className={classes.buttonAdd} onClick={handleCreatePartner} disabled={disableSubmitButton}>
        Submit
      </button>
      {/* <button className={classes.buttonAdd} onClick={handleCreatePartner}>
        Submit
      </button> */}
      <Modal
        show={show}
        centered
        classNames={{
          modal: "customModal",
        }}
      >
        <h6 style={{ margin: "20px 10px", paddingLeft: "7px" }}>
          Do you want to add City ?
        </h6>
        <p className={classes.label_item1}>
          City Name <span style={{ color: "red" }}>*</span>
        </p>
        <input
          type="text"
          className={classes.smallInput1}
          onChange={(e) => setAddCity(e.target.value)}
        />
        <p
          className={
            validate1 && !AddCity
              ? classes.errorText1
              : classes.errorTextHidden1
          }
        >
          Please Enter City Name
        </p>
        <div className={classes.buttonText}>
          <button className={classes.buttonAdditem} onClick={handleAdd}>
            Yes
          </button>
          <button className={classes.buttonCancle} onClick={handleAddCancle}>
            No
          </button>
        </div>
      </Modal>

      <Modal
        show={show2}
        centered
        classNames={{
          modal: "customModal",
        }}
      >
        <h6 style={{ margin: "20px 10px", paddingLeft: "7px" }}>
          Do you want to add Currency ?
        </h6>
        <p className={classes.label_item1}>
          Currency Name <span style={{ color: "red" }}>*</span>
        </p>
        <input
          type="text"
          className={classes.smallInput1}
          onChange={(e) => {
            setFormCurrency({
              ...formCurrency,
              currencyName: e.target.value,
            });
          }}
        />
        <p
          className={
            validate2 && !formCurrency?.currencyName?.trim()
              ? classes.errorText1
              : classes.errorTextHidden1
          }
        >
          Please Enter Currency Name
        </p>
        <p className={classes.label_item1}>
          Currency Code <span style={{ color: "red" }}>*</span>
        </p>
        <input
          type="text"
          className={classes.smallInput1}
          onChange={(e) => {
            setFormCurrency({
              ...formCurrency,
              currencyCode: e.target.value,
            });
          }}
        />
        <p
          className={
            validate2 && !formCurrency?.currencyCode?.trim()
              ? classes.errorText1
              : classes.errorTextHidden1
          }
        >
          Please Enter Currency Name
        </p>
        <div className={classes.buttonText}>
          <button className={classes.buttonAdditem} onClick={handleAddCurrency}>
            Yes
          </button>
          <button className={classes.buttonCancle} onClick={handleAddCancle2}>
            No
          </button>
        </div>
      </Modal>
    </>
  );
};
export default CreateClientList;
